import * as React from 'react';
import StepperProgress from '../components/stepper';
import { useLocation } from 'react-router-dom';
import apiPath from "../Api/apiPath";
import makeTheApiCall from '../Api/axiosConfig';
import VerifyDetails from '../components/form-validation';
import ImageCapture from '../components/image-capture';
import SuccessComp from '../components/capture.success';
import AppHeader from '../components/app-header';
import AppFooter from '../components/app-footer';
import { Grid, Typography } from '@mui/material';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function HorizontalLinearAlternativeLabelStepper() {
  let query = useQuery();
  const userId = query.get('applicant');

  /** User Step status. Get what is the completion status of user */;
  const [ApplicantStatus, setApplicantStatus] = React.useState(-1);
  /**END */

  /** Get Applicant data from userID and store in a state */
  const [ApplicantData, setApplicantData] = React.useState(null);
  React.useEffect(()=>{
    if(userId){
      let apiOptions = {
        url :apiPath.GET_Applicant_Public,
        params :{
         id:userId
        },
        method:"get"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseObj = response.success ? response?.success?.data?.result : null;
        setApplicantData(responseObj);
        if(responseObj && responseObj.is_completed === 0){
          setApplicantStatus(Number(0));
        }
        if(responseObj && responseObj.is_completed === 1){
          setApplicantStatus(Number(3));
        }
        if(userId && !responseObj){
          alert('Project has been archived. Please contact your administrator.');
          window.location.href="/"
        }
      });
    }else{
      window.location.href="/"
    }
  },[userId]);
  /**END */

  return (
    <>
    <AppHeader />
    <Typography textAlign="center" style={{marginTop:"10px"}}>{ApplicantData?.project_id?.name}</Typography>
    <StepperProgress status={ApplicantStatus}/>
    <Grid
    sx={{ minHeight: '100vh' }}
    >
    {
      ApplicantStatus === 0 && (
        <VerifyDetails data={ApplicantData} userId={userId} setApplicantStatus={setApplicantStatus}/>
      )
    }
    {
      ApplicantStatus === 1 && (
        <ImageCapture data={ApplicantData} userId={userId} setApplicantStatus={setApplicantStatus}/>
      )
    }
    {
      ApplicantStatus === 3 && (
        <SuccessComp data={ApplicantData} />
      )
    }

    </Grid>
    
     <AppFooter />
    </>
  );
}
