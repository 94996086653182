import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import { EmailOutlined, PhoneAndroid } from '@mui/icons-material';


export default function AppFooter() {
  return (
    <React.Fragment>
      <CssBaseline />
      
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
            Helpline
          <IconButton color="inherit" aria-label="open drawer">
            <PhoneAndroid /> 
          </IconButton>
          9166521764 (Monday - Saturday 9:00 AM to 6:00 PM )
        </Toolbar>
        <Toolbar>
        Support 
          <IconButton color="inherit" aria-label="open drawer">
            <EmailOutlined /> 
          </IconButton>
          onlinedv.rssb@gmail.com  itcell.rsmssb@rajasthan.gov.in
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
