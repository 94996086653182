import axios from "axios";
import pathApiObj from "./apiPath";

const InstanceAxios = axios.create({
  baseURL: pathApiObj.baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});
const makeTheApiCall = (apiOptions)=> {
    return new Promise((callback)=>{
      
        InstanceAxios({
            method: apiOptions.method,
            url: apiOptions.url,
            data: apiOptions.data,
            params:apiOptions.params
          }).then((response)=>{
            if(response.data){
              return callback({
                success: response.data,
                reject : false
              });
            }else{
              return callback({
                success: response.data,
                reject : true
              });
            }
          }).catch((err)=>{
            return callback({
              success :null,
              reject:true
            });
          })
    });
}

export default makeTheApiCall 