import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';

export default function SuccessComp({data}) {
  return (
    <Grid container justify="center" style={{ marginTop: "20px" }}>
        <Grid
          item
          sx={{ maxWidth: 345 }}
          display="flex"
          style={{ margin: "auto" }}
          alignItems="center"
          justifyContent="center"
        >
            <Card variant="outlined">
            <CardActionArea>
                <CardMedia
                component="img"
                height="140"
                image="/success-image.png"
                alt="green iguana"
                />
                <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Dear <b>{data?.applicant_name}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Your details has been successfully submitted.
                </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
        </Grid>
    </Grid>
  );
}
