import React from "react";
import {
  Route,
  BrowserRouter,
  Routes
} from "react-router-dom";
import HomePage from "./pages/home";
import UserVerifyPage from "./pages/user-verify"
import LinkPage from "./pages/link";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
function App() {
  return (
    <>
     <BrowserRouter>
     <React.Suspense fallback={loading}>
     <Routes>
          <Route
            exact
            path="/"
            name="Home Page"
            element={
              <HomePage />
            }
          />
          <Route
            exact
            path="/link"
            name="Link Page"
            element={
              <LinkPage />
            }
          />
          <Route
            exact
            path="/customer-step"
            name="Verification Page"
            element={
              <UserVerifyPage />
            }
          />
          <Route
            exact
            path="/customer-verify-success"
            name="Success Page"
            element={
              <HomePage />
            }
          />
        </Routes>
      </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
