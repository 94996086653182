import { Container, Grid,Skeleton, Typography } from '@mui/material'
import React from 'react'
import AppHeader from '../components/app-header'
import AppFooter from '../components/app-footer'

function HomePage() {
  return (
    <>
    <AppHeader />
    <Container>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '60vh' }}
    >
      <Typography  color="ActiveCaption" style={{fontFamily: 'calibri'}}>NURSE (GNM) (CONTRACTUAL) RECRUITMENT - 2023</Typography>
      <Typography  color="ActiveCaption" style={{fontFamily: 'calibri'}}>ONLINE DOCUMENT VERIFICATION</Typography>
      <Grid item xs={3}>
      <Skeleton animation="wave" variant="circular" width={250} height={250} />
      {/* <Typography variant="subtitle1" color="ActiveCaption">You are not authorized person to access this.</Typography>
      <Typography variant="subtitle1" color="ActiveCaption">Please contact your administrator.</Typography> */}
      </Grid>
    </Grid>
    </Container>
    <AppFooter />
    </>
  )
}

export default HomePage
