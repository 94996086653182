import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
function AppHeader() {

  return (
    <AppBar position="static" >
      <Container minWidth="xl">
        <Toolbar disableGutters>
          <Box>
            <Avatar alt="Rajasthan Staff Selection Board" src="/logo.jpg" />
          </Box> 
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              ml: 2,
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'calibri',
              letterSpacing: {xs:'.1rem',md:'.3rem'},
              color: 'inherit',
              textDecoration: 'none',
              fontSize:{xs:'14px',md:'32px'}
            }}
          >
            Rajasthan Staff Selection Board, Jaipur
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppHeader;
