import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import apiPath from "../Api/apiPath";
import makeTheApiCall from '../Api/axiosConfig';
import 'animate.css';

function ImageCapture({userId,setApplicantStatus}) {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [isImageCapture, setIsImageCapture] = useState(false);
  const [textLabel, setTextLabel] = useState("Capture");
  const [disableBtn, setDisableBtn] = useState(false);

  // create a capture function
  const capture = useCallback(() => {
    const btn =document.getElementById('textCapture');
    btn.classList.add('animate__fadeOut');
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setIsImageCapture(true);
    setTimeout(() => {
      btn.classList.remove('animate__fadeOut');
      setTextLabel("Re-Capture");
    }, 1000);
  }, [webcamRef]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const submitImage =()=>{
    setDisableBtn(true);
    let apiOptions = {
      url :apiPath.Upload_to_s3,
      data :{
      buffer:imgSrc,applicant_id:userId,location:{}
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        uploadVideoToS3("",'test-video');
        setApplicantStatus(3);
      }else{
        toast.error("Please re-try to submit.");
        setDisableBtn(false)
      }
    })

    /**
    //upload capture image to s3 using api
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function(pos) {
        let location ={
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        };
        let apiOptions = {
          url :apiPath.Upload_to_s3,
          data :{
          buffer:imgSrc,applicant_id:userId,location:location
          },
          method:"post"
        };
        makeTheApiCall(apiOptions).then((response)=>{
          
          let responseObj = response.success ? response?.success?.data : null;
          if(responseObj){
            uploadVideoToS3("",'test-video');
            setApplicantStatus(3);
          }else{
            toast.error("Please re-try to submit.");
            setDisableBtn(false)
          }
        })
      })
    }else{
      
    }
     */
  };

  const uploadVideoToS3=()=>{
    let apiOptions = {
      url :apiPath.Upload_Video_to_s3,
      data :{
        // blob:blob,
        applicant_id:userId
      },
      method:"post"
    };
    makeTheApiCall(apiOptions).then((response)=>{
      let responseObj = response.success ? response?.success?.data : null;
      if(responseObj){
        setApplicantStatus(3)
      }else{
        toast.error("Please re-try to submit.");
        setDisableBtn(false)
      }
    })
  };

  return (
    <>
      <Grid container justify="center" style={{ marginTop: "10px" }}>
        <Grid
          item
          sx={{ maxWidth: 345 }}
          display="flex"
          style={{ margin: "auto" }}
          alignItems="center"
          justifyContent="center"
        >
          <Card variant="outlined">
            <CardContent className="center">
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Verify you Identity
              </Typography>
              <Webcam
                ref={webcamRef}
                height={200}
                width={320}
                screenshotFormat="image/jpeg"
                style={{ marginLeft: "-25px" }}
              />
            </CardContent>
            <CardActions>
              
              <Button size="small" id="textCapture" className="animate__animated" variant="contained" disabled={disableBtn} onClick={capture}>
                {textLabel}
              </Button>
              {
                isImageCapture && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={(e) => setOpen(true)}
                    disabled={disableBtn}
                  >
                    Preview Image
                  </Button>
                )
              }
              {
                isImageCapture && (
              <Button
                size="small"
                variant="contained"
                onClick={(e) => submitImage()}
                disabled={disableBtn}
              >
                Submit
              </Button>
                )
              }
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{"Image preview"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {imgSrc && (
              <img src={imgSrc} height={220} width={220} alt="webcam" />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImageCapture;
