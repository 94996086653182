import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { toast } from 'react-toastify';
import apiPath from "../Api/apiPath";
import makeTheApiCall from '../Api/axiosConfig';
function TimerComponent({data,applicationNumber,userId,setOtpSendSuccess}) {
    /** Timer functionality */
  const [Timer,setTimer] = React.useState(120);
  const [showTimer,setShowTimer] = React.useState(false);
  const [disabledBtn,setDisabledBtn] = React.useState(false);

  const startTimerFunc= React.useCallback(()=>{
    setShowTimer(true);
    setDisabledBtn(true)
    let interval = setInterval(() =>{setTimer((timer)=>timer-1);}, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setTimer(null);
      setShowTimer(false);
      setDisabledBtn(false)
    }, 120000);
  },[]);
  /**End */

  /** send OTP */
  const sendOTP =()=>{
    if(data?.applicant_name && applicationNumber){
      //**Call Generate OTP API */
      let apiOptions = {
          url :apiPath.Applicant_OTP_GENERATE,
          data :{
           id:userId,
           application_number:applicationNumber
          },
          method:"POST"
        };
        makeTheApiCall(apiOptions).then((response)=>{
          let responseData = response?.success?.data;
          if(responseData){
            toast.success("OTP has been send to registered mobile number.");
            startTimerFunc();
            setOtpSendSuccess(true);
          }else{
            toast.error('Application Number Does Not Match - गलत एप्लीकेशन नंबर')
          }
        });
  }else{
      toast.info('Please provide application number.') 
  }
  }
  return (
    <>
      <Grid container style={{marginTop:"10px"}}>
            <Grid item>
                {
                    showTimer && (
                    <Typography>
                        Resend OTP in :{Timer}
                    </Typography>
                    )
                }
            <Button
                type="button"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={e=>sendOTP()}
                disabled={disabledBtn}
            >
                Generate OTP
            </Button>
            </Grid>
        </Grid>
    </>
  )
}

export default TimerComponent
