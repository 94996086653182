
let URL =" https://api.idtfy.in/api/";

let pathApiObj = {
  baseURL: URL,
  //S3 URL
  Upload_to_s3        : 'applicant/upload-capture-image',
  Upload_Video_to_s3  : "applicant/upload-capture-video",

  //Applicant
  GET_Applicant_Public  : "applicant/public-detail",
  Get_Applicant_Validate: "applicant/public-detail-validate",
  Applicant_OTP_Validate: "otp/public-detail-validate",
  Applicant_OTP_GENERATE: "otp/send-otp",
  Get_Redirect_Url      : "applicant/public-url",

};
export default pathApiObj;
