import * as React from "react";
import makeTheApiCall from "../Api/axiosConfig";
import apiPath from "../Api/apiPath";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LinkPage() {
  let query = useQuery();
  const applicantId = query.get("id");
  //get applicant data
  React.useEffect(() => {
    if (applicantId) {
      let apiOptions = {
        url: apiPath.Get_Redirect_Url,
        params: {
          id: applicantId,
        },
        method: "get",
      };
      makeTheApiCall(apiOptions).then((response) => {
        let responseObj = response.success ? response?.success?.data : null;
        if (responseObj) {
          window.location.href = responseObj?.url;
        } else {
          window.location.href = "/";
        }
      });
    }else{
        window.location.href="/"
    }
  }, [applicantId]);
  return <div></div>;
}

export default LinkPage;
