import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TimerComponent from './timer-component';
import { toast } from 'react-toastify';
import apiPath from "../Api/apiPath";
import makeTheApiCall from '../Api/axiosConfig';
const defaultTheme = createTheme();

export default function VerifyDetails({data,userId,setApplicantStatus}) {
  const [applicationNumber, setApplicationNumber] = React.useState("");
  const [OTP, setOTP] = React.useState("");
  const [otpSendSuccess, setOtpSendSuccess] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
   
    if(!applicationNumber){
      toast.error("Please provide application number.");
      return;
    }
    if(!OTP){
      toast.error("Please provide 4 digit OTP.");
      return;
    };
    /** Call Validate API */
    if(userId){
      let apiOptions = {
        url :apiPath.Applicant_OTP_Validate,
        data :{
         id:userId,
         application_number:applicationNumber,
         otp:OTP
        },
        method:"post"
      };
      makeTheApiCall(apiOptions).then((response)=>{
        let responseData = response?.success?.data;
        if(responseData){
          setApplicantStatus(1);
        }else{
          toast.error('OTP is invalid or expired.')
        }
      });
    }else{
      toast.info('Please re-try later!!') 
    }
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify Details
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="applicant_name"
              name="applicant_name"
              value={data?.applicant_name}
              disabled
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="father_name"
              name="father_name"
              autoComplete="Full Name"
              value={data?.father_name}
              disabled
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="application_number"
              label="Application Number"
              type="number"
              id="application_number"
              onChange={e=>setApplicationNumber(e.target.value)}
            />
            <Typography component="span" variant="caption">
                * Provide application number to generate OTP.*
            </Typography>
            {
              otpSendSuccess && (
                <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="OTP "
                  label="OTP"
                  type="number"
                  id="OTP"
                  onChange={e=>setOTP(e.target.value)}
                />
                <Typography component="span" variant="caption">
                    * Provide 4 digit OTP.*
                </Typography>
                </>
              )
            }
            <TimerComponent data={data} applicationNumber={applicationNumber} userId={userId} setOtpSendSuccess={setOtpSendSuccess}/>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!otpSendSuccess}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}